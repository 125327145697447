export const CONTRACTS = {
  7777: {
    name: 'LUX_MAINNET',
    chain_id: 7777,
    teleporter: '0xC58E07f1C07627af94ad77549499b642aeFf0a3C',
    vault: '0x7C6e3BE7B72805f0f52d788b96695b9d7e59A00c',
  },
  1: {
    name: 'ETHEREUM_MAINNET',
    chain_id: 1,
    teleporter: '0xebD1Ee9BCAaeE50085077651c1a2dD452fc6b72e',
    vault: '0xcf963Fe4E4cE126047147661e6e06e171f366506',
  },
  137: {
    name: 'POLYGON_MAINNET',
    chain_id: 137,
    teleporter: '0xE09C9b6Ed2BADAa97AB00652dF75da05adc6dAeF',
    vault: '0x217feE2a1a6A31Dda68433270531F56C91EC8D2B',
  },
  10: {
    name: 'OPTIMISM_MAINNET',
    chain_id: 10,
    teleporter: '0xbdCE894aEd7d30BA0C0D0B51604ee9d225fc8b95',
    vault: '0x37d9fB96722ebDDbC8000386564945864675099B',
  },
  42161: {
    name: 'ARBITRUM_MAINNET',
    chain_id: 42161,
    teleporter: '',
    vault: '',
  },
  42220: {
    name: 'CELO_MAINNET',
    chain_id: 42220,
    teleporter: '',
    vault: '',
  },
  8453: {
    name: 'BASE_MAINNET',
    chain_id: 8453,
    teleporter: '0x37d9fB96722ebDDbC8000386564945864675099B',
    vault: '0x3226bb1d3055685EFC1b0E49718B909a1c6Ce18d',
  },
  56: {
    name: 'BSC_MAINNET',
    chain_id: 56,
    teleporter: '0xebD1Ee9BCAaeE50085077651c1a2dD452fc6b72e',
    vault: '0xcf963Fe4E4cE126047147661e6e06e171f366506',
  },
  100: {
    name: 'GNOSIS_MAINNET',
    chain_id: 100,
    teleporter: '',
    vault: '',
  },
  43114: {
    name: 'AVAX_MAINNET',
    chain_id: 43114,
    teleporter: '',
    vault: '',
  },
  250: {
    name: 'FANTOM_MAINNET',
    chain_id: 250,
    teleporter: '',
    vault: '',
  },
  1313161554: {
    name: 'AURORA_MAINNET',
    chain_id: 1313161554,
    teleporter: '',
    vault: '',
  },
  7777777: {
    name: 'ZORA_MAINNET',
    chain_id: 7777777,
    teleporter: '',
    vault: '',
  },
  81457: {
    name: 'BLAST_MAINNET',
    chain_id: 81457,
    teleporter: '',
    vault: '',
  },
  59144: {
    name: 'LINEA_MAINNET',
    chain_id: 59144,
    teleporter: '',
    vault: '',
  },
  ///////////////////////////////////////// testnets ///////////////////////////////////////////
  11155111: {
    name: 'ETHEREUM_SEPOLIA',
    chain_id: 11155111,
    teleporter: '0xb34e8a93A56De724c432fD41052E715657Fb0B1D',
    vault: '0x63139CFe43cEe881332a2264851940108333e8D0',
  },
  17000: {
    name: 'HOLESKY_TESTNET',
    chain_id: 17000,
    teleporter: '0x2951A9386df11a4EA8ae5A823B94DC257dEb35Cb',
    vault: '0xebD1Ee9BCAaeE50085077651c1a2dD452fc6b72e',
  },
  84532: {
    name: 'BASE_SEPOLIA',
    chain_id: 84532,
    teleporter: '0xE139056397091c15F860E94b20cCc169520b086A',
    vault: '0x2c4908f1B607A4448272ea29A054bf9AA08A1D76',
  },
  97: {
    name: 'BSC_TESTNET',
    chain_id: 97,
    teleporter: '0xD293337c79A951C0e93aEb6a5E255097d65b671f',
    vault: '0xe0feC703840364714b97272973B8945FD5eB5600',
  },
  8888: {
    name: 'LUX_TESTNET',
    chain_id: 8888,
    teleporter: '0x7d462c69057E404a172690E8C5021563382CAa78',
    vault: '0xD1496b961855c3F554F3F71A653915EEe035c55e',
  },
}

export const SWAP_PAIRS: Record<string, string[]> = {
  LETH: ['ETH'],
  LBNB: ['BNB'],
  LFTM: ['FTM'],
  LPOL: ['POL', 'MATIC'],
  LUSD: ['USDT', 'USDC', 'DAI'],
  LCELO: ['CELO'],
  LXDAI: ['XDAI'],

  ETH: ['LETH'],
  POL: ['LPOL'],
  BNB: ['LBNB'],
  FTM: ['LFTM'],
  DAI: ['LUSD'],
  USDT: ['LUSD'],
  USDC: ['LUSD'],
  CELO: ['LCELO'],
  XDAI: ['LXDAI'],
}
