export default () => (
  <div className="flex place-content-center mb-4">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 116 116"
      fill="none"
    >
      <circle cx="58" cy="58" r="58" fill="#55B585" fillOpacity="0.1" />
      <circle cx="58" cy="58" r="45" fill="#55B585" fillOpacity="0.3" />
      <circle cx="58" cy="58" r="30" fill="#55B585" />
      <path
        d="M44.5781 57.245L53.7516 66.6843L70.6308 49.3159"
        stroke="white"
        strokeWidth="3.15789"
        strokeLinecap="round"
      />
    </svg>
  </div>
)
